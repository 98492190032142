import { Link, Box, Container } from '@mui/material'

export function DesktopTopHeader() {
  return (
    <Box
      sx={(theme) => ({
        display: { xs: 'none' },
        '@media(min-width: 768px)': {
          display: 'flex',
        },
        justifyContent: 'flex-end',
        [theme.breakpoints.up('md')]: {
          top: 0,
          zIndex: theme.zIndex.appBar,
        },
      })}
    >
      <Container
        maxWidth={false}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacings.sm,
            height: '31px',
            padding: `0 ${theme.spacings.md}`,
            background: theme.palette.background.dark,
            textTransform: 'uppercase',
            borderBottomLeftRadius: '30px',
            borderBottomRightRadius: '30px',
            fontWeight: 700,
          })}
        >
          <Link
            href='/zakelijk-leasen'
            color='primary.contrastText'
            underline='none'
            variant='overline'
            fontWeight={700}
          >
            Zakelijk leasen
          </Link>
          <Link
            href='/private-fietslease'
            color='primary.contrastText'
            underline='none'
            variant='overline'
            fontWeight={700}
          >
            Private Fietslease
          </Link>
          <Link
            href='/inloggen-partners'
            color='primary.contrastText'
            underline='none'
            variant='overline'
            fontWeight={700}
          >
            B2B Login
          </Link>
          <Link
            href='/contact'
            color='primary.contrastText'
            underline='none'
            sx={{ typography: 'overline' }}
            fontWeight={600}
          >
            Contact
          </Link>
        </Box>
      </Container>
    </Box>
  )
}
